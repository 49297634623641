.tilted-top,
.tilted-bottom {
    position: relative;
    margin: auto;
    background: var(--mainColor);
}

/* tilted top */
.tilted-top {
    transform: skewY(-2deg);
}

.tilted-top .unskew {
    transform: skewY(2deg);
}

.tilted-top .button-top {
    position: absolute;
    right: 0;
    top: 0;
    transform: skewY(2deg) translate(-20px, -50%);
}

.tilted-top .button-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: skewY(2deg) translate(-20px, 50%);
}

/* tilted bottom */
.tilted-bottom {
    transform: skewY(2deg);
}

.tilted-bottom .unskew {
    transform: skewY(-2deg);
}

.tilted-bottom .button-top {
    position: absolute;
    right: 0;
    top: 0;
    transform: skewY(-2deg) translate(-20px, -50%);
}

.tilted-bottom .button-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: skewY(-2deg) translate(-20px, 50%);
}

.tagline {
    padding: 5% 0 8% 0;
}

/* contact */
.top-slash-contact {
    max-width: var(--widthBlueStripe);
    background: url(../asset/svg/two-stripes-top.svg);
    background-size: cover;
    margin-top: -65px;
    padding-top: 100px;
}

@media only screen and (min-width: 900px) {
    .tagline {
        padding: 5% 0 8% 0;
        margin-left: 50%;
    }
    .tagline-background {
        background-image: url(../asset/image/happy-customer.jpg);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .tilted-top,
    .tilted-bottom {
        max-width: var(--widthBlueStripe);
    }

    .tilted-top .button-top,
    .tilted-top .button-bottom,
    .tilted-bottom .button-top,
    .tilted-bottom .button-bottom {
        margin-right: calc((var(--widthBlueStripe) - var(--widthWhiteStripe)) / 2);
    }
    .tilted-top .button-top {
        transform: skewY(2deg) translate(50%, -50%);
    }
    .tilted-top .button-bottom {
        transform: skewY(2deg) translate(50%, 50%);
    }
    .tilted-bottom .button-top {
        transform: skewY(-2deg) translate(50%, -50%);
    }
    .tilted-bottom .button-bottom {
        transform: skewY(-2deg) translate(50%, 50%);
    }
    .coverup {
        background: var(--mainColor);
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
        top: 0;
    }
}