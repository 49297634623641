body {
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    color: var(--secondaryColor);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

h2 .subtext {
    display: block;
    padding: 20px 0;
}

.outline {
    text-shadow: 
        -1px -1px 0 #0000005d,  
        1px -1px 0 #0000005d,
        -1px 1px 0 #0000005d,
        1px 1px 0 #0000005d;
}

.clickable {
    cursor: pointer;
    text-decoration-color: var(--secondaryColor);
}

.noor {
    position: absolute;
    max-width: 100%;
    width: auto;
    height: 150vh;
}

.short-border {
    width: calc(70vw * 0.97);
    height: 1px;
    margin: auto;
    background: var(--secondaryColor);
    margin-bottom: 30px;
}

.pricing-switch {
    display: inline-flex;
}

.pricing-switch p {
    margin-top: auto;
    margin-bottom: auto;
}

.white-stripe {
    background: linear-gradient(
        90deg,
        var(--lightGray) 15%,
        white 15%,
        white 85%,
        var(--lightGray) 85%
    );
}

.white-stripe.short-top {
    padding-top: 25px; 
}

.background-gray {
    padding-bottom: 60px;
    background: linear-gradient(90deg, var(--lightGray) 15%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, var(--lightGray) 85%);
}

.background-gray-image {
    position: relative;
    padding-bottom: 25px;
    max-width: 800px;
    margin: auto;
    padding-top: 30px;
}

.fullFlex {
    display: flex;
    width: 100%;
}

.logo {
    height: 55px;
    width: auto;
}

.loading {
    padding-top: 100px;
    background: white;
    width: 100%;
    text-align: center;
}

.header-trigger {
    width: 50px;
    height: 50px;
    margin-left: 50%;
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, var(--offset));
}

.mobile-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.inactive {
    display: none;
    pointer-events: none;
}

@media only screen and (max-width: 1024px) {
    :root {
        --widthWhiteStripe: 60vw;
        --widthBlueStripe: 80vw;
    }
}

@media only screen and (min-width: 900px) {
    .white-stripe {
        background: linear-gradient(
            90deg,
            var(--lightGray) calc((100% - var(--widthWhiteStripe)) / 2),
            white calc((100% - var(--widthWhiteStripe)) / 2),
            white calc(50% + var(--widthWhiteStripe) / 2),
            var(--lightGray) calc(50% + var(--widthWhiteStripe) / 2)
        );
    }
    .white-stripe .occupy {
        margin: auto;
        width: var(--widthWhiteStripe);
    }
    .background-gray {
        padding-bottom: 0;
        background: linear-gradient(
            90deg,
            var(--lightGray) 20%,
            rgba(0, 0, 0, 0) 60%,
            rgba(0, 0, 0, 0) calc(100% - 60%),
            var(--lightGray) calc(100% - 60%)
        );
    }
    .tagline.background-gray {
        margin-left: 0;
        padding-bottom: 0;
        background: linear-gradient(
            90deg,
            var(--lightGray) calc((100% - var(--widthBlueStripe)) / 2),
            rgba(0, 0, 0, 0) calc((100% - var(--widthBlueStripe)) / 2),
            rgba(0, 0, 0, 0) calc(50% + var(--widthBlueStripe) / 2),
            var(--lightGray) calc(50% + var(--widthBlueStripe) / 2)
        );
    }
    .short-border {
        width: calc(var(--widthWhiteStripe) * 0.97);
        margin-bottom: 40px;
    }
    .background-gray-image {
        display: flex;
    }
    .noor {
        width: 100vw;
        height: auto;
    }
}
